import './scripts/common';
import './scripts/common-nav';

if (!window.HTMLPictureElement) {
    import('picturefill');
}

let _config = window['_config'] || {};

let app = {
    debug: false,
};
window.app = app || {};


/**********************************
 *
 *      Project Stuff
 *
 * ********************************/

import * as experienceSlider from './scripts/experience-slider';
experienceSlider.init();

import * as lightbox from './scripts/elements-packages/lightbox';
lightbox.init();

import * as regionSlider from './scripts/regionSlider';
regionSlider.init();

import * as playVideo from './scripts/playVideo';
playVideo.init();

import * as contentVisibility from './scripts/content-visibility';
contentVisibility.init();

console.log('test cl')
